import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate  } from 'react-router'

export const DetalleServicio = () => {

    const navigate = useNavigate();

    const { servicio } = useSelector(state => state.detalleServicio);

    const handleBack = () => {
        navigate('/servicios');
      }

    let data = undefined;

    switch (servicio) {
        case 0:
            data = <>
                <div>0</div>
            </>;
            break;
        case 1:
            data = <>
                <div className='detalle-servicio-header'>Constitución de Sociedades</div>
                <div className='detalle-servicio-text mb-3'>Cuando dos o más personas deciden reunir capitales y voluntades para desarrollar sus proyectos productivos, lo recomendable es que constituyan una sociedad, la que podemos definir como una entidad, organización o institución a través de la cual se realizan actividades mercantiles.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>3 Propuestas de nombre en orden de preferencia, para el permiso de denominación ante la Secretaría de Economía.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cédula de identificación fiscal de cada uno de los socios.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de nacimiento de cada socio.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de matrimonio de los socios (en su caso).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial de los socios (IFE, pasaporte vigente ó cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Objeto de la sociedad (a que se va a dedicar la sociedad).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Duración de la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Importe del capital social con el que se va a constituir la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La cantidad que cada socio aportará en dinero o en otros bienes (en caso de aportar inmuebles se deberá presentar un avalúo bancario).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>El domicilio de la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La designación del administrador y representante legal de la sociedad o en su caso del Consejo de Administración.</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Constitución de Sociedad Civil</div>
                <div className='detalle-servicio-text mb-3'>Una sociedad civil es una corporación de naturaleza privada a la que se otorga personalidad jurídica de manera permanente para realizar un fin común, lícito y posible de carácter preponderante económico.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>3 Propuestas de nombre en orden de preferencia (para el permiso de denominación ante la Secretaría de Economía).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cédula de identificación fiscal de cada uno de los socios.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de nacimiento de cada socio.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de matrimonio de los socios (en su caso).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial de los socios (IFE, pasaporte vigente ó cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Objeto de la sociedad (a que se va a dedicar la sociedad).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Duración de la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Importe del capital social con el que se va a constituir la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La cantidad que cada socio aportará en dinero o en otros bienes (en caso de aportar inmuebles se deberá presentar un avalúo bancario).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>El domicilio de la sociedad.</div>
                    </li>
                </ul>
            </>;
            break;
        case 2:
            data = <>
                <div className='detalle-servicio-header'>Protocolización de Acta de Asamblea</div>
                <div className='detalle-servicio-text mb-3'>Las actas de asamblea son los acuerdos, decisiones, modificaciones y/o resultados a los que ha llegado la empresa en un determinado momento. Estos se respaldan bajo un documento que queda legalmente asentado y formalizado ante notario público. </div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Acta constitutiva.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Protocolizaciones de actas de asamblea que se hayan celebrado anteriormente.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cédula de Identificación fiscal de la sociedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Última declaración provisional de impuestos de la empresa.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Orden del día.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial vigente de cada socio: (IFE, pasaporte vigente o cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Formato de datos generales proporcionado por la Notaría.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>CURP de cada socio.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cédula de identificación fiscal de cada socio.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de nacimiento de cada socio.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de matrimonio de cada socio.</div>
                    </li>
                </ul>
            </>;
            break;
        case 3:
            data = <>
                <div className='detalle-servicio-header'>Poder</div>
                <div className='detalle-servicio-text'>Es un documento público autorizado por un notario que permite a una persona física o moral designar a otra como su representante para que actúe en su nombre en determinados actos jurídicos.</div>
                <div className='detalle-servicio-text mb-3'>El poder permite al representante acreditar su calidad de apoderado mediante la exhibición de la copia autorizada o testimonio del poder. </div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial del poderdante (credencial de elector, pasaporte, cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta constitutiva y poder del representante (en caso de que el otorgante sea persona moral).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cedula del Registro Federal de Contribuyentes (RFC).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Cédula de la Clave Única de Registro de Población (CURP).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Si alguna de las personas que intervengan en la operación es extranjera deberá acreditar</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>su legal estancia en el país con su FM2, FM3 o tarjeta de residencia.</div>
                    </li>
                </ul>
            </>;
            break;
        case 4:
            data = <>
                <div className='detalle-servicio-header'>Compraventa</div>
                <div className='detalle-servicio-text mb-3'>Es un contrato que sirve par establecer de manera legal un acuerdo entre dos personas: un Vendedor, quien transfiere un inmueble que es de su propiedad, y un Comprador, quien paga un precio establecido por el bien inmueble.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Escritura de la propiedad en original con sello de inscripción del Registro Público de Propiedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Recibo del impuesto predial pagado.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Nacimiento Original.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Matrimonio original.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial vigente con fotografía.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Comprobante de domicilio reciente.</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Compraventa INFONAVIT</div>
                <div className='detalle-servicio-text mb-3'>Es un contrato de compraventa con crédito hipotecario el cual otorga INFONAVIT por medio de la notaria, Las condiciones de tu crédito se establecen con base en tu precalificación, que considera tu edad y tu salario, tu ahorro en la Subcuenta de Vivienda y los bimestres de cotización continua que has tenido lo cual te informa directamente INFONAVIT.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Escritura de la propiedad en original con sello de inscripción del Registro Público de Propiedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Boletas de impuesto predial.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Nacimiento.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Matrimonio original y actualizada en caso de ser casado.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial vigente con fotografía</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Comprobante de domicilio reciente.</div>
                    </li>
                </ul>
            </>;
            break;
        case 5:
            data = <>
                <div className='detalle-servicio-header'>Donación</div>
                <div className='detalle-servicio-text mb-3'>Se llama donación a la operación que se lleva a cabo cuando en vida una persona entrega a otra un bien inmueble a título gratuito, al aceptar la donación es fundamental acudir ante notario público para formalizarla legalmente.</div>
                <div className='detalle-servicio-header'>Requisitos del donante:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Escritura de la propiedad en original con sello de inscripción del Registro Público de Propiedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Recibo del impuesto predial pagado.</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Requisitos del donante y donatario:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Nacimiento Original.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Matrimonio original.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial vigente con fotografía.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Comprobante de domicilio reciente.</div>
                    </li>
                </ul>
            </>;
            break;
        case 6:
            data = <>
                <div className='detalle-servicio-header'>Adjudicación testamentaria</div>
                <div className='detalle-servicio-text mb-3'>Al fallecer una persona que otorgó testamento, su voluntad queda expresada en el mismo y sus herederos pueden acudir ante Juez o Notario a iniciar el trámite sucesorio.</div>
                <div className='detalle-servicio-header'>Para acudir ante Notarios se requerirá:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Que no haya controversia entre los herederos.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Que éstos sean mayores de edad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Que el testamento que se exhiba hubiere sido otorgado ante Notario (Testamento Público Abierto).</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Ventajas de tramitar una sucesión testamentaria ante Notario y no ante Juez:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Los interesados pueden elegir libremente con qué Notario llevar el trámite.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La sucesión se concluye en menos tiempo.</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>El trámite sucesorio notarial se compone principalmente de tres etapas:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>La de aceptación de Herencia y designación y aceptación del cargo de Albacea.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La de protocolización de inventarios y avalúos.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>La de partición y/o Adjudicación de los bienes.</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Defunción del fallecido</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de nacimiento del fallecido</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Testamento</div>
                    </li>
                </ul>
                <div className='detalle-servicio-header'>Adjudicación intestamentaria</div>
                <div className='detalle-servicio-text mb-3'>Cuando una persona fallece sin haber dejado testamento, o habiéndolo dejado no dispone de todos sus bienes o por alguna causa legal no surte sus efectos, la Ley suple su voluntad y como consecuencia determina quienes son las personas que tienen derecho a heredar el patrimonio del fallecido.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Defunción (Original y copia).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Acta de Matrimonio (Original y copia).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Actas de Nacimiento del finado, del cónyuge y de los hijos (originales y copia).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación de los comparecientes (credenciales de elector, pasaporte o cedula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>CURP de los comparecientes.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Copia Escritura de Propiedad.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Copia Predial del año en curso.</div>
                    </li>
                </ul>
            </>;
            break;
        case 7:
            data = <>
                <div className='detalle-servicio-header'>Fe de Hechos</div>
                <div className='detalle-servicio-text'>La Fe de Hechos consiste en una diligencia realizada por el Notario quien, con la fe pública que ejerce, deja constancia cierta de algún hecho que esté ocurriendo ante él, esto sin hacer juicios de valor pues el notario se limita a la observación objetiva.</div>
                <div className='detalle-servicio-text mb-3'>El acta de fe de hechos tiene un valor probatorio pleno, lo cual significa que cuenta con la calidad de un documento público como si fuera expedido por una autoridad.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Carta de instrucción dirigida al notario, en la cual se deberá asentar, lugar, fecha y hora para el desahogo de la diligencia (en su caso).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial del solicitante (credencial de elector, pasaporte, cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Clave única de registro de población (CURP) de las partes.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>En caso de acudir en representación de una persona física o moral, presentar poder notarial.</div>
                    </li>
                </ul>
            </>;
            break;
        case 8:
            data = <>
                <div className='detalle-servicio-header'>Ratificación de Firmas o Documentos</div>
                <div className='detalle-servicio-text'>Es un acto a través del cual el notario público certifica que la firma que figura en un documento corresponde a la persona signataria. El reconocimiento de firmas involucra la confrontación de la firma del documento y la firma de la persona, también es posible que el notario certifique que las firmas de un documento fueron realizadas bajo su presencia.</div>
                <div className='detalle-servicio-text mb-3'>Los documentos que pueden ratificarse ante notario son todos aquellos a los que se quiera dar autenticidad y dotar de una fecha cierta.</div>
                <div className='detalle-servicio-header'>Requisitos:</div>
                <ul>
                    <li>
                        <div className='detalle-servicio-text'>Documento original donde consten las firmas que se ratifican.</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Identificación oficial del solicitante (credencial de elector, pasaporte, cédula profesional).</div>
                    </li>
                    <li>
                        <div className='detalle-servicio-text'>Clave única de registro de población (CURP) de las partes.</div>
                    </li>
                </ul>
            </>;
            break;
    
        default:
            data = <>
                <div>Default</div>
            </>;
            break;
    }


    return (
        <>
            <div className='centrar-vh servicios-banner'>
                <div>
                <div className='text-center servicios-banner-text-header'>Nuestros Servicios</div>
                <div className='text-center servicios-banner-text-subheader'>Le ofrecemos una amplia gama de servicios apegados a nuestros valores de honestidad e integridad</div>
                </div>
            </div>
            <div className='container pb-5'>
                <div className='servicios-back-btn' onClick={handleBack}>
                    ← Servicios
                </div>
                {data}
            </div>
        </>
    );

}
