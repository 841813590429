import React from 'react'
import { Link } from 'react-router-dom'

export const ServiciosBanner = () => {

    return (
        <div className='servicios-banner-container'>
            <div className='container'>
                <div className='servicios-banner-header'>Nuestros servicios</div>
                <div className='servicios-banner-subheader'>Somos expertos</div>
                <div className='servicios-divider-container'>
                    <div className='servicios-divider'></div>
                </div>
                <div className='row'>
                    <div className='col-md-3 col-sm-6 col-xs-12'>
                        <div className='servicios-banner-servicio servicios-banner-servicio-1'></div>
                        <div className='servicios-banner-servicio-description'>Derecho Mercantil y Corporativo</div>
                    </div>
                    <div className='col-md-3 col-sm-6 col-xs-12'>
                        <div className='servicios-banner-servicio servicios-banner-servicio-2'></div>
                        <div className='servicios-banner-servicio-description'>Derecho Civil</div>
                    </div>
                    <div className='col-md-3 col-sm-6 col-xs-12'>
                        <div className='servicios-banner-servicio servicios-banner-servicio-3'></div>
                        <div className='servicios-banner-servicio-description'>Derecho Inmobiliario</div>
                    </div>
                    <div className='col-md-3 col-sm-6 col-xs-12'>
                        <div className='servicios-banner-servicio servicios-banner-servicio-4'></div>
                        <div className='servicios-banner-servicio-description'>Otros Servicios</div>
                    </div>
                </div>
                <div className='centrar'>
                    <Link to="/servicios">
                        <button type="button" className="btn servicios-banner-btn">Conozca todo nuestro catálogo de servicios</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
