import React from 'react'
import { ContactoForm } from '../components/ContactoForm'
import { useDispatch } from 'react-redux'
import { useNavigate  } from 'react-router'

import { detalleServicioSetServicio } from '../actions/detalleServicio'

export const Servicios = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDetalleServicio = (servicio) => {
    dispatch(detalleServicioSetServicio(servicio));
    navigate('/detalle-servicio');
  }

  return (
    <>
      <div className='centrar-vh servicios-banner'>
        <div>
          <div className='text-center servicios-banner-text-header'>Nuestros Servicios</div>
          <div className='text-center servicios-banner-text-subheader'>Le ofrecemos una amplia gama de servicios apegados a nuestros valores de honestidad e integridad</div>
        </div>
      </div>
      <div className='container'>
        <div className='servicios-container'>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <div className="card servicios-card">
                <div className="row g-0">
                  <div className="col-md-12 col-lg-2 centrar-vh">
                    <div className='servicios-img servicios-img-1'></div>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div className="card-body">
                      <h5 className="servicios-card-title">Derecho Mercantil y Corporativo</h5>
                      <ul>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(1)}><span className='servicios-card-link'>Constitución de Sociedades</span></li>
                        <li className="servicios-card-text">Fusión</li>
                        <li className="servicios-card-text">Escisión</li>
                        <li className="servicios-card-text">Transformación</li>
                        <li className="servicios-card-text">Disolución y Liquidación</li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(2)}><span className='servicios-card-link'>Protocolización de Actas de Asamblea y Consejo</span></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-4'>
              <div className="card servicios-card">
                <div className="row g-0">
                  <div className="col-md-12 col-lg-2 centrar-vh">
                    <div className='servicios-img servicios-img-2'></div>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div className="card-body">
                      <h5 className="servicios-card-title">Derecho Civil</h5>
                      <ul>
                        <li className="servicios-card-text">Testamentos</li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(3)}><span className='servicios-card-link'>Poderes</span></li>
                        <li className="servicios-card-text">Revocación de Poderes</li>
                        <li className="servicios-card-text">Sucesiones Testamentales</li>
                        <li className="servicios-card-text">Cesiones de Derecho (principalmente litigiosos y/o adjudicatarios)</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6 mb-4'>
              <div className="card servicios-card">
                <div className="row g-0">
                  <div className="col-md-12 col-lg-2 centrar-vh">
                    <div className='servicios-img servicios-img-3'></div>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div className="card-body">
                      <h5 className="servicios-card-title">Derecho Inmobiliario</h5>
                      <ul>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(4)}><span className='servicios-card-link'>Compraventa</span></li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(5)}><span className='servicios-card-link'>Donación</span></li>
                        <li className="servicios-card-text">Permuta</li>
                        <li className="servicios-card-text">Condominios</li>
                        <li className="servicios-card-text">Aportación a Sociedades</li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(6)}><span className='servicios-card-link'>Adjudicaciones por Herencia</span></li>
                        <li className="servicios-card-text">Adjudicaciones por Remate Judicial</li>
                        <li className="servicios-card-text">Formalización de Transmisiones de Propiedad (por divorcio, por juicios ordinarios civiles de otorgamiento y firma de escrituras).</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6 mb-4'>
              <div className="card servicios-card">
                <div className="row g-0">
                  <div className="col-md-12 col-lg-2 centrar-vh">
                    <div className='servicios-img servicios-img-4'></div>
                  </div>
                  <div className="col-md-12 col-lg-10">
                    <div className="card-body">
                      <h5 className="servicios-card-title">Otros Servicios</h5>
                      <ul>
                        <li className="servicios-card-text">Cotejo de Documentos y Expedición de copias certificadas</li>
                        <li className="servicios-card-text">Notificaciones</li>
                        <li className="servicios-card-text">Interpelaciones</li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(7)}><span className='servicios-card-link'>Fe de Hechos</span></li>
                        <li className="servicios-card-text" onClick={() => handleDetalleServicio(8)}><span className='servicios-card-link'>Ratificaciones</span></li>
                        <li className="servicios-card-text">Declaraciones</li>
                        <li className="servicios-card-text">Trámite de Apostillas</li>
                        <li className="servicios-card-text">Legalizaciones</li>
                        <li className="servicios-card-text">Servicios de Medicación, Negociación y Solución de Conflictos</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactoForm theme='dark' />
    </>
  )
}
