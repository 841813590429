import React from 'react'
import { ContactoForm } from '../components/ContactoForm'

export const Contacto = () => {
  return (
    <>
      <div className='centrar-vh contacto-banner'>
        <div>
          <div className='text-center contacto-banner-text-header'>Contáctenos</div>
          <div className='text-center contacto-banner-text-subheader'>¡Déjenos saber sus dudas y comentarios!</div>
        </div>
      </div>
      <div className='contacto-columns'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-4'>
              <div className='centrar'>
                <div className='contacto-columns-icon contacto-columns-marcador-icon'></div>
              </div>
              <div className='contacto-columns-text'>Monte Everest No. 100 esquina Sierra Fría Fracc. Bosques del Prado Norte Aguascalientes, Ags.</div>
            </div>
            <div className='col-sm-4'>
              <div className='centrar'>
                <div className='contacto-columns-icon contacto-columns-telefono-icon'></div>
              </div>
              <div className='contacto-columns-text'>(449) 996 58 02 al 04</div>
              <div className='contacto-columns-text'>(449) 996 19 74</div>
            </div>
            <div className='col-sm-4'>
              <div className='centrar'>
                <div className='contacto-columns-icon contacto-columns-correo-icon'></div>
              </div>
              <div className='contacto-columns-text'><a className='contacto-columns-text-link' href='mailto:contacto@notaria47.org'>contacto@notaria47.org</a></div>
            </div>
          </div>
        </div>
      </div>
      <iframe className='contacto-mapa' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3701.4358173388136!2d-102.31509178802025!3d21.917790979879943!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8429ef1d3139f949%3A0x18ce48279d7ba4b8!2sNotaria%20P%C3%BAblica%2047!5e0!3m2!1ses-419!2smx!4v1708045823654!5m2!1ses-419!2smx" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='Notaria 47'></iframe>
      <ContactoForm theme='light' />
    </>
  )
}
