import React, { useEffect } from 'react'
import { ContactoForm } from '../components/ContactoForm'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import { equipoGetData } from '../actions/equipo'

export const Equipo = () => {

  const dispatch = useDispatch();
  const { isLoading, equipo } = useSelector(state => state.equipo);

  useEffect(() => {

    if(equipo == null){
      dispatch(equipoGetData());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const equipoMembers = (equipo != null && equipo.length !== 0) ? equipo.map((miembro, index) => 
    <div key={`equipo_${ index }`} className='col-md-3'>
      <div className="card equipo-card">
        <div style={{ backgroundImage: `url(${ miembro.imagen })` }} className="card-img-top equipo-img"></div>
        <div className="card-body">
          <h5 className="equipo-header">{ miembro.nombre }</h5>
          <p className="equipo-puesto">{ miembro.puesto }</p>
          <p className="equipo-text">{ miembro.experiencia }</p>
        </div>
      </div>
    </div>
  ) : <div className='text-center'>Obteniendo información ...</div>;

  return (
    <>
      <div className='centrar-vh equipo-banner'>
        <div>
          <div className='text-center equipo-banner-text-header'>Equipo</div>
          <div className='text-center equipo-banner-text-subheader'>Apasionados por nuestro trabajo</div>
        </div>
      </div>
      {(!isLoading)
      ? <div className='container'>
        <div className='equipo-container'>
          <div className='row'>
            {equipoMembers}
          </div>
        </div>
      </div>
      : <></>}
      <ContactoForm theme='dark' />
    </>
  )
}
