import { types } from "../types/types";

const equipoStartGetData = () => ({
    type: types.homeCarouselStartGetData,
    payload: {}
});

export const equipoGetData = () => {
    return async(dispatch)=>{
        try {
            dispatch(equipoStartGetData());
            const resp = await fetch( 'https://api.notaria47.org/api/equipo', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            });
            const body = await resp.json();
            if(body.status === true){
                dispatch(equipoSetData(body.equipo));
            }else{
                dispatch(equipoSetData([]));
            }
        } catch (error){
            dispatch(equipoSetData([]));
        }
    }
}

const equipoSetData = (equipo) => ({
    type: types.equipoSetData,
    payload: equipo
});