import { types } from "../types/types";

const initialState = {
    equipo: null,
    isLoading: false
};

export const equipoReducer=(state = initialState, action)=>{
    switch (action.type){
        case types.equipoStartGetData:
            return{
                ...state,
                isLoading: true
            }
        case types.equipoSetData:
            return{
                ...state,
                equipo: action.payload,
                isLoading: false
            }
        
        default:
            return state;
            
    }

}