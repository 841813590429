import React from 'react'

export const ClientesBanner = () => {
  return (
    <div className='clientes-banner-container'>
        <div className='container'>
            <div className='clientes-banner-header'>Nuestros clientes</div>
            <div className='clientes-banner-subheader'>Nuestros clientes forman parte fundamental de nosotros, por lo que agradecemos la preferencia de nuestra cartera de clientes, entre los cuáles se encuentran:</div>
            <div className='clientes-divider-container'>
                <div className='clientes-divider'></div>
            </div>
            <div className='row'>
                <div className='col-md-3 col-sm-6 col-xs-12'>
                    <div className='clientes-banner-cliente clientes-banner-cliente-1'></div>
                </div>
                <div className='col-md-3 col-sm-6 col-xs-12'>
                    <div className='clientes-banner-cliente clientes-banner-cliente-2'></div>
                </div>
                <div className='col-md-3 col-sm-6 col-xs-12'>
                    <div className='clientes-banner-cliente clientes-banner-cliente-3'></div>
                </div>
                <div className='col-md-3 col-sm-6 col-xs-12'>
                    <div className='clientes-banner-cliente clientes-banner-cliente-4'></div>
                </div>
            </div>
        </div>
    </div>
  )
}
