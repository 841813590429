import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import Swal from "sweetalert2"
import { Input } from 'antd';
import InputMask from 'react-input-mask';

import { useForm } from '../hooks/useForm'

export const ConsultaExpedienteForm = ({ theme }) => {

    const [exampleExpediente] = useState(`0${ Math.floor(Math.random() * 9) }${ Math.floor(Math.random() * 9) }${ Math.floor(Math.random() * 9) }${ Math.floor(Math.random() * 9) }/${ new Date().getFullYear() }`);

    const [currentForm, setCurrentForm] = useState(true);

    const [request, setRequest] = useState('');

    const initialForm = {
        expediente: '',
        correo: '',
        username: '',
        lastname: ''
    }

    const [ formValues, handleInputChange, reset ] = useForm(initialForm);

    const {expediente, correo, username, lastname} = formValues;

    const [formValidation, setFormValidation] = useState({
        expediente: null,
        correo: null
    });

    const [secureCode, setsecureCode] = useState('');

    const handleSubmitData = async (e) =>{
        e.preventDefault();
        if(isFormValid()){
            try {
                const resp = await fetch( 'https://api.notaria47.org/api/solicitud-expediente', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ expediente, correo }),
                    redirect: 'follow'
                });
                const body = await resp.json();
                if(body.status === true){
                    reset();
                    clearForm();
                    setCurrentForm(false);
                    setRequest(body.solicitud);
                    Swal.fire({
                        icon: 'success',
                        title: 'Ingresa el código recibido.',
                        text: 'Con el objetivo de garantizar la seguridad y privacidad de tus datos hemos enviado un código de 6 dígitos a tu correo electrónico, con el verificamos la autenticidad de la solicitud.',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#3085d6"
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al enviar información',
                        text: body.msg,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al enviar información, intenta nuevamente.',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }
    }

    const handleChangeCode = async (code) =>{
        setsecureCode(code);
    }

    const handleSubmitCode = async (e) =>{
        e.preventDefault();
        if(/[0-9]{6}/gs.test(secureCode)){
            try {
                const resp = await fetch( `https://api.notaria47.org/api/solicitud-expediente/${ request }`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ codigo: secureCode }),
                    redirect: 'follow'
                });
                const body = await resp.json();
                if(body.status === true){
                    setsecureCode('');
                    setCurrentForm(true);
                    Swal.fire({
                        icon: 'success',
                        title: 'Solicitud verificada',
                        text: 'Revisa tu correo electrónico, hemos enviado los detalles del estado acutal de tu expediente.',
                        showConfirmButton: true,
                        confirmButtonText: 'Aceptar',
                        confirmButtonColor: "#3085d6"
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al enviar código, intenta nuevamente.',
                        // text: body.msg,
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al enviar código, intenta nuevamente.',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }else{
            Swal.fire({
                icon: 'error',
                title: 'Código de seguridad incorrecto, intenta nuevamente.',
                showConfirmButton: false,
                timer: 2000
            });
        }
    }

    const handleBack = () => {
        setCurrentForm(true);
        setsecureCode('');

    }

    const isFormValid = () => {
        let flag = true;
        let expedienteState = true;
        let correoState = true;
        //Nombre
        if(validator.isEmpty(expediente) || !/[0-9]{5}\/20[0-9]{2}/gs.test(expediente)){
            flag = false;
            expedienteState = false;
        }
        //Correo
        if(!validator.isEmail(correo)){
            flag = false;
            correoState = false;
        }
        //Username
        if(!validator.isEmpty(username)){
            flag = false;
        }
        //Actualización de estado
        setFormValidation({
            ...formValidation,
            expediente: expedienteState,
            correo: correoState
        });
        return flag;
    }

    const isFieldValid = (field = '') => {
        let expedienteState = undefined;
        let correoState = undefined;
        switch (field) {
            case 'expediente':
                if(!validator.isEmpty(expediente) && /[0-9]{5}\/20[0-9]{2}/gs.test(expediente)){
                    expedienteState = true;
                }else{
                    expedienteState = false;
                }
                break;
            case 'correo':
                if(validator.isEmail(correo)){
                    correoState = true;
                }else if(correo !== ''){
                    correoState = false;
                }
                break;
        
            default:
                break;
        }
        //Actualización de estado
        setFormValidation({
            ...formValidation,
            expediente: expedienteState,
            correo: correoState
        });
    }

    const clearForm = () => {
        setFormValidation({
            ...formValidation,
            expediente: null,
            correo: null
        });
    }

    useEffect(() => {
        isFieldValid('expediente');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expediente])

    useEffect(() => {
        isFieldValid('correo');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correo])

    return (
        <div className='contacto-form-container' style={{backgroundColor: (theme === 'dark') ? '#1B1B1B' : 'transparent'}}>
            {(currentForm)
            ? <div className='container'>
                <div className='contacto-form-header' style={{color: (theme !== 'dark') ? 'var(--gold-color)' : 'white'}}>Consulta el estatus de tu expediente</div>
                <div className='contacto-form-subheader' style={{color: (theme !== 'dark') ? 'black' : 'var(--white-nav-color)'}}>Requeriras acceso a tu correo electrónico durante el proceso de consulta</div>
                <form onSubmit={handleSubmitData}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='mb-4'>
                                <InputMask
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }${ (formValidation.expediente != null) ? ((formValidation.expediente) ? ' is-valid' : ' is-invalid') : '' }`}
                                    name="expediente"
                                    mask="99999/9999"
                                    onChange={handleInputChange}
                                    placeholder="No. de expediente"
                                    value={expediente}
                                />
                                <div className="invalid-feedback">
                                    Expediente invalido
                                </div>
                                <small className="form-text text-muted">
                                    El número de expediente se compone de 5 dígitos correspondientes a la clave, seguidos de una barra diagonal y por último 4 dígitos correspondientes al año en que ingreso el expediente (Ej. { exampleExpediente }).
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }${ (formValidation.correo != null) ? ((formValidation.correo) ? ' is-valid' : ' is-invalid') : '' }`}
                                    name="correo"
                                    onChange={handleInputChange}
                                    placeholder="Correo electrónico"
                                    type="text"
                                    value={correo}
                                />
                                <div className="invalid-feedback">
                                    Correo invalido
                                </div>
                                <small className="form-text text-muted">
                                    Correo electónico asociado al expediente.
                                </small>
                            </div>
                        </div>
                    </div>
                    <div className='row d-none'>
                        <div className='col'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"                                    
                                    name="username"
                                    onChange={handleInputChange}
                                    placeholder="Username"
                                    type="text"
                                    value={username}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-dark form-control-contacto-btn' type='submit'>Consultar</button>
                        </div>
                    </div>
                </form>
            </div>
            : <div className='container'>
                <div className='contacto-form-header' style={{color: (theme !== 'dark') ? 'var(--gold-color)' : 'white'}}>Código de seguridad</div>
                <div className='contacto-form-subheader' style={{color: (theme !== 'dark') ? 'black' : 'var(--white-nav-color)'}}>Revisa tu bandeja de entrada</div>
                <form onSubmit={handleSubmitCode}>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='mb-4'>
                                <Input.OTP
                                    length={6}
                                    onChange={handleChangeCode}
                                    value={ secureCode }
                                    variant="filled"
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row d-none'>
                        <div className='col'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"                                    
                                    name="lastname"
                                    onChange={handleInputChange}
                                    placeholder="Lastname"
                                    type="text"
                                    value={lastname}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-dark form-control-contacto-btn' type='submit'>Enviar código</button>
                            <button className='btn btn-dark form-control-contacto-btn ms-2' type='button' onClick={handleBack}>Regresar</button>
                        </div>
                    </div>
                </form>
            </div>}
        </div>
    )
}

ConsultaExpedienteForm.propTypes = {
    theme: PropTypes.string.isRequired,
}