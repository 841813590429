import { useRouteError } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <>
        {/* Navbar */}
        <nav className="navbar navbar-expand-sm bg-white">
            <Link className="navbar-brand" to="/">
              <div>
                <span className="allergien-logo-text">Notaria 47</span>
              </div>
            </Link>
            <div className="navbar-collapse">
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/">Inicio</Link>
                </div>
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/servicios">Servicios</Link>
                </div>
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/fundamentos">Fundamentos</Link>
                </div>
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/notario">Notario</Link>
                </div>
                <div className="navbar-nav">
                    <Link className="nav-item nav-link" to="/contacto">Contacto</Link>
                </div>
            </div>
        </nav>
        <div className="main-container">
            <div className="text-center">Oops!</div>
            <div className="text-center">Lo sentimos, un error inesperado ha ocurrido.</div>
            <div className="text-center">{error.statusText || error.message}</div>
        </div>
        {/* Footer */}
        <footer className="text-center text-lg-start bg-light text-muted">
          <div className="text-center p-4">
            © 2024 Copyright&nbsp;
            <Link to="/" className="text-reset fw-bold">Notaria47.org</Link>
          </div>
        </footer>
        {/* Footer */}
      </>
  );
}