import React from 'react';
import ReactDOM from 'react-dom/client';

import './styles.css';

import { NotariaApp } from './NotariaApp';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NotariaApp />
  </React.StrictMode>
);