import React from 'react'
import { ContactoForm } from '../components/ContactoForm'

export const Notario = () => {
  return (
    <>
      <div className='centrar-vh notario-banner'>
        <div>
          <div className='text-center notario-banner-text-header'>Notario</div>
          <div className='text-center notario-banner-text-subheader'>Dr. Alejandro Durán Márquez</div>
        </div>
      </div>
      <div className='container'>
        <div className='notario-container'>
          <div className='row mb-5'>
            <div className='col-md-6'>
              <div className='notario-img'></div>
            </div>
            <div className='col-md-6'>
              <div className='notario-text'>Doctor en Derecho por la Universidad Autónoma de Nayarit.</div>
              <div className='notario-text'>Maestro en Derecho Civil por la Universidad Panamericana, Campus Bonaterra.</div>
              <div className='notario-text'>Licenciado en Derecho por la Universidad Panamericana, Campus Bonaterra.</div>
            </div>
          </div>
          <div className='notario-header'>Dr. Alejandro Durán Márquez</div>
          <div className='notario-text mb-3'>Notario</div>
          <div className='notario-text mb-3'>Correo: <a href='mailto:alejandro.duran@notaria47.org' className='notario-highligth'>alejandro.duran@notaria47.org</a></div>
          <div className='notario-text mb-3'>Teléfono: 449 928 42 34</div>
        </div>
      </div>
      <ContactoForm theme='dark' />
    </>
  )
}
