
export const types = {

    homeCarouselStartGetData: '[home-carousel] Start getting home carousel data',
    homeCarouselSetData: '[home-carousel] Set home carousel data',

    detalleServicioSetServicio: '[detalle-servicio] Set detalle servicio servicio',

    equipoStartGetData: '[equipo] Start getting equipo data',
    equipoSetData: '[equipo] Set equipo data',
    
}