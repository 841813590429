import { types } from "../types/types";

const homeCarouselStartGetData = () => ({
    type: types.homeCarouselStartGetData,
    payload: {}
});

export const homeCarouselGetData = () => {
    return async(dispatch)=>{
        try {
            dispatch(homeCarouselStartGetData());
            const resp = await fetch( 'https://api.notaria47.org/api/home-carousel', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow'
            });
            const body = await resp.json();
            if(body.status === true){
                dispatch(homeCarouselSetData(body.slides));
            }else{
                dispatch(homeCarouselSetData([
                    {
                        background: 'https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                        header: 'Brindamos certeza jurídica a las personas y sus bienes',
                        subheader: 'mediante la prestación de servicios notariales de manera eficiente y apegada a la legalidad'
                    }
                ]));
            }
        } catch (error){
            dispatch(homeCarouselSetData([
                {
                    background: 'https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
                    header: 'Brindamos certeza jurídica a las personas y sus bienes',
                    subheader: 'mediante la prestación de servicios notariales de manera eficiente y apegada a la legalidad'
                }
            ]));
        }
    }
}

const homeCarouselSetData = (slides) => ({
    type: types.homeCarouselSetData,
    payload: slides
});