import React from 'react';
import { Provider } from 'react-redux';
import {
    createBrowserRouter,
    RouterProvider
} from "react-router-dom";

import { store } from './store/store';

import Root from "./routes/Root";
import { Index } from "./routes/Index";
import { Servicios } from "./routes/Servicios";
import { DetalleServicio } from "./routes/DetalleServicio";
import { Fundamentos } from "./routes/Fundamentos";
import { Notario } from "./routes/Notario";
import { Equipo } from "./routes/Equipo";
import { Contacto } from "./routes/Contacto";
import { AvisoPrivacidad } from "./routes/AvisoPrivacidad";
import ErrorPage from "./components/ErrorPage";
import { CancelarSolicitud, loader as cancelarSolicitudTokenLoader } from "./routes/CancelarSolicitud";

const router = createBrowserRouter([
    {
      path: "",
      element: <Root/>,
      errorElement: <ErrorPage/>,
      children: [
        {
          path: "/",
          element: <Index />,
        },
        {
            path: "/servicios",
            element: <Servicios />,
        },
        {
          path: "/detalle-servicio",
          element: <DetalleServicio />,
      },
        {
            path: "/fundamentos",
            element: <Fundamentos />,
        },
        {
            path: "/notario",
            element: <Notario />,
        },
        {
          path: "/equipo",
          element: <Equipo />,
        },
        {
            path: "/contacto",
            element: <Contacto />,
        },
        {
          path: "/aviso-privacidad",
          element: <AvisoPrivacidad />,
        },
        {
          path: "/solicitud-expediente-cancelar/:id/:token",
          element: <CancelarSolicitud />,
          loader: cancelarSolicitudTokenLoader,
        },
      ],
    },
]);

export const NotariaApp = () => {
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </>
  )
}
