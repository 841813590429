import React from 'react'
import { useLoaderData } from 'react-router-dom';
import { useTokenFetch } from '../hooks/useTokenFetch';

export async function loader({ params }) {
    return {
        id: params.id,
        token: params.token
    };
}

export const CancelarSolicitud = () => {

  const { id, token } = useLoaderData();

  const { loading, data, error, error_msg } = useTokenFetch(`/solicitud-expediente/${ id }`, token, 'DELETE');

  return (
    <>
      {loading
        ? (
          <div className='container'>
            <div className='alert alert-info text-center mt-3'>verifying...</div>
          </div>
        )
        : (
          error
          ? (
            <div className="container">
                <div class="card mt-3">
                    <div class="card-header">
                        Notaria 47
                    </div>
                    <div class="card-body">
                        <div className="cancelar-solicitud-header">Solicitud no cancelada!</div>
                        <div className="cancelar-solicitud-subheader mt-2">{error_msg}</div>
                    </div>
                </div>
            </div>
          )
          : (
            <div className="container">
                <div class="card mt-3">
                    <div class="card-header">
                        Notaria 47
                    </div>
                    <div class="card-body">
                        <div className="cancelar-solicitud-header">Solicitud cancelada</div>
                        <div className="cancelar-solicitud-subheader mt-2">¡Gracias por tu confianza!</div>
                    </div>
                </div>
            </div>
          )
        )
      }
    </>
  )
}
