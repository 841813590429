import React from 'react'

export const ImageBackOnContainer = ({content}) => {
  return (
    <div className='background-on-container-back'>
        <div className='background-on-container-container'>
            {content}
        </div>
    </div>
  )
}
