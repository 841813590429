import React from 'react'
import { ContactoForm } from '../components/ContactoForm'

export const Fundamentos = () => {
  return (
    <>
      <div className='centrar-vh fundamentos-banner'>
        <div>
          <div className='text-center fundamentos-banner-text-header'>Fundamentos estratégicos</div>
          <div className='text-center fundamentos-banner-text-subheader'>Brindar certeza jurídica a las personas y sus bienes</div>
        </div>
      </div>
      <div className='container'>
        <div className='fundamentos-container'>
          <div className='fundamentos-header'>Misión</div>
          <div className='fundamentos-text mb-3'>Brindar certeza jurídica a las personas y sus bienes, mediante la prestación de servicios notariales de manera eficiente y apegada a la legalidad, dotando nuestro actuar de un alto contenido ético y alto profesionalismo.</div>
          <div className='fundamentos-header'>Visión</div>
          <div className='fundamentos-text mb-3'>Ser reconocidos como una Notaría Pública de excelencia debido a nuestra alta calidad, apego a la legalidad y bajos tiempos de respuesta, contando con personal altamente calificado, con espacios dignos y modernos y mediante el aprovechamiento de tecnologías de vanguardia, en beneficio de todos nuestros clientes.</div>
          <div className='fundamentos-header'>Valores</div>
          <ul>
              <li>
                  <div className='fundamentos-text'>Honestidad</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Transparencia</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Legalidad</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Imparcialidad</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Discreción</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Trabajo en equipo</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Orientación al Cliente</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Responsabilidad</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Calidad</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Orden</div>
              </li>
              <li>
                  <div className='fundamentos-text'>Pulcritud</div>
              </li>
          </ul>
        </div>
      </div>
      <ContactoForm theme='dark' />
    </>
  )
}
