import React from 'react'
import { CarouselCaptionsIndicators } from '../components/CarouselCaptionsIndicators'
import { ContactoForm } from '../components/ContactoForm'
import { ClientesBanner } from '../components/ClientesBanner'
import { ServiciosBanner } from '../components/ServiciosBanner'
import { ImageBackOnContainer } from '../components/ImageBackOnContainer'
import { ConsultaExpedienteForm } from '../components/ConsultaExpedienteForm'

export const Index = () => {
  return (
    <>
      <CarouselCaptionsIndicators />
      <ServiciosBanner />
      <ImageBackOnContainer content={<ContactoForm theme='light' />} />
      <ClientesBanner />
      <ImageBackOnContainer content={<ConsultaExpedienteForm theme='light' />} />
    </>
  )
}
