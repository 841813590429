import React from 'react'

export const AvisoPrivacidad = () => {
  return (
    <>
      <div className='centrar-vh aviso-banner'>
        <div className='text-center aviso-banner-text'>Aviso de Privacidad</div>
      </div>
      <div className='container p-5'>
        <p className='aviso-text'>La protección de sus datos personales es muy importante para esta Notaría, razón por la cual, este AVISO DE PRIVACIDAD, elaborado para dar cumplimiento a la LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESION DE LOS PARTICULARES, tiene como fin informarle el tipo de datos personales que recabamos de usted, cómo los usamos, manejamos y aprovechamos, y con quién los compartimos.</p>
        <p className='aviso-header'>¿Qué datos personales recabamos de usted?</p>
        <p className='aviso-text'>Como cliente de alguno de nuestros servicios le podemos solicitar información personal, que varía según el caso, relativa a:</p>
        <ul className='aviso-text'>
          <li>Su nombre, lugar y fecha de nacimiento, estado civil, domicilio, ocupación, RFC, CURP, correo electrónico y números telefónicos.</li>
          <li>Información sobre su cónyuge, padres, hijos, herederos, legatarios y otros beneficiarios.</li>
          <li>Sus datos patrimoniales como cuentas bancarias, créditos, bienes muebles e inmuebles, activos y pasivos, entre otros.</li>
          <li>Comprobantes oficiales que acrediten su identidad y la información que usted declara.</li>
        </ul>
        <p className='aviso-header'>¿Para qué usamos sus datos personales?</p>
        <p className='aviso-text'>Esta Notaría recaba y usa sus datos personales para el cumplimiento de las siguientes finalidades:</p>
        <ul className='aviso-text'>
          <li>Confirmar su identidad.</li>
          <li>Entender y atender sus necesidades de carácter legal en materia notarial.</li>
          <li>Otorgar seguridad y certeza jurídica a los hechos y actos que celebra, como pueden ser operaciones traslativas de dominio relativas a compraventas, donaciones, permutas, adjudicaciones por herencia, adjudicaciones por remate, fideicomisos; así como constituciones de regímenes de propiedad en condominio; testamentos; trámites sucesorios; hipotecas; cancelaciones de hipotecas; otorgamiento de poderes, ratificaciones de firmas; fe de hechos; cotejo de documentos; entre otros.</li>
          <li>Brindarle asesoría legal.</li>
          <li>Elaborar los instrumentos notariales de su interés.</li>
          <li>Cumplir con los requerimientos legales que le son aplicables.</li>
          <li>Verificar la información que nos proporciona.</li>
        </ul>
        <p className='aviso-header'>¿Con quién compartimos su información y para qué fines?</p>
        <p className='aviso-text'>Sus datos personales sólo son tratados por el personal adscrito a esta Notaría a efecto de elaborar los instrumentos públicos notariales que usted solicita, por lo cual, sus datos personales no se transfieren a ningún tercero ajeno a ella, salvo para el cumplimiento de obligaciones legales ante las autoridades competentes tales como los registros públicos, las autoridades tributarias, así como las autoridades judiciales, entre otras.</p>
        <p className='aviso-header'>¿Cómo puede limitar el uso o divulgación de su información personal?</p>
        <p className='aviso-text'>Usted puede limitar el uso y divulgación de su información personal a través de los siguientes medios que hemos instrumentado:</p>
        <ul className='aviso-text'>
          <li>Presentando su solicitud personalmente en nuestro domicilio dirigida al responsable del tratamiento de datos personales en esta oficina.</li>
          <li>Enviando correo electrónico a la siguiente dirección electrónica: <a href='mailto:contacto@notaria47.org' className='aviso-highligth'>contacto@notaria47.org</a></li>
          <li>Llamando al número telefónico: (449) 9965802</li>
        </ul>
        <p className='aviso-header'>¿Cómo acceder, rectificar, cancelar u oponerse al tratamiento de sus datos personales?</p>
        <p className='aviso-text'>El ejercicio de los derechos de acceso, rectificación, cancelación y oposición o la revocación del consentimiento, podrán efectuarse presentando solicitud por escrito en nuestro domicilio dirigida a la persona encargada de la privacidad en esta oficina, o bien, vía correo electrónico a <a href='mailto:contacto@notaria47.org' className='aviso-highligth'>contacto@notaria47.org</a> o llamando al número (449) 9965802, en el entendido de que una vez plasmados en un instrumento notarial, no podrá usted ejercer sobre ellos ninguno de los referidos derechos, pudiendo hacerlo solamente respecto de los que se conservan en la base de datos de la Notaría.</p>
        <p className='aviso-header'>¿Cómo conocer los cambios al presente aviso de privacidad?</p>
        <p className='aviso-text'>El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones por lo cual nos comprometemos a mantenerlo informado de tal situación a través de nuestra página de Internet: <a href='https://www.notaria47.org' className='aviso-highligth'>www.notaria47.org</a>.</p>
        <p className='aviso-header'>¿Cómo contactarnos?</p>
        <p className='aviso-text'>Si usted tiene alguna duda sobre el presente aviso de privacidad, puede dirigirla a:</p>
        <ul className='aviso-text'>
          <li>La dirección electrónica: <a href='mailto:contacto@notaria47.org' className='aviso-highligth'>contacto@notaria47.org</a></li>
          <li>La dirección de correo postal, dirigida al responsable del tratamiento de datos personales en esta oficina (Dr. Arturo Durán García).</li>
          <li>Al teléfono: (449) 9965802</li>
          <li>Así mismo, ponemos a su entera disposición copias del presente aviso de privacidad en nuestro domicilio</li>
        </ul>
        <p className='aviso-text'><b>Ultima revisión: junio 2013</b></p>
      </div>
    </>
  )
}
