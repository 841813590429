import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { homeCarouselGetData } from '../actions/homeCarousel'

export const CarouselCaptionsIndicators = () => {

  const dispatch = useDispatch();
  const { isLoading, slides } = useSelector(state => state.homeCarousel);

  useEffect(() => {

    if(slides == null){
      dispatch(homeCarouselGetData());
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const slideItems = (slides != null) ? slides.map((slide, index) => 
    <div key={`home_slide_${ index }`} className={`carousel-item${ (index === 0) ? ' active' : '' }`} data-bs-interval="7500">
      <div className="d-block w-100 carousel-img" style={{backgroundImage: `url(${ slide.background })`}}></div>
      <div className="carousel-caption">
        <p className='carousel-header'>{ slide.header }</p>
        <p className='carousel-subheader'>{ slide.subheader }</p>
      </div>
    </div>
  ) : '';

  const slideIndicators = (slides != null) ? slides.map((slide, index) => 
    <button key={`home_slide_indicator_${ index }`} type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to={index} className={`${ (index === 0) ? 'active' : '' }`} aria-label={`Slide ${index}`}></button>
  ) : '';

  return (
    (!isLoading)
    ? <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        { slideIndicators }
      </div>
      <div className="carousel-inner">
        { slideItems }
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
    : <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" className="active" aria-label="Slide 0"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="7500">
          <div className="d-block w-100 carousel-img" style={{backgroundImage: 'url(https://images.unsplash.com/photo-1603796846097-bee99e4a601f?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)'}}></div>
          <div className="carousel-caption d-none d-md-block">
            <p className='carousel-header'>Brindamos certeza jurídica a las personas y sus bienes</p>
            <p className='carousel-subheader'>mediante la prestación de servicios notariales de manera eficiente y apegada a la legalidad</p>
          </div>
        </div>
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}