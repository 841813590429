import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import validator from 'validator'
import Swal from "sweetalert2"

import { useForm } from '../hooks/useForm'

export const ContactoForm = ({ theme }) => {

    const initialForm = {
        nombre: '',
        telefono: '',
        correo: '',
        mensaje: '',
        username: ''
    }
    
    const [ formValues, handleInputChange, reset ] = useForm(initialForm);

    const {nombre, telefono, correo, mensaje, username} = formValues;

    const [formValidation, setFormValidation] = useState({
        nombre: null,
        telefono: null,
        correo: null
    });

    const handleSubmit = async (e) =>{
        e.preventDefault();
        if(isFormValid()){
            try {
                const resp = await fetch( 'https://api.notaria47.org/api/contacto', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ nombre, telefono, correo, mensaje }),
                    redirect: 'follow'
                });
                const body = await resp.json();
                if(body.status === true){
                    reset();
                    clearForm();
                    Swal.fire({
                        icon: 'success',
                        title: 'Gracias, nos comunicaremos a la brevedad.',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: 'Error al enviar información, intenta nuevamente.',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
            } catch (error) {
                // console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error al enviar información, intenta nuevamente.',
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        }
    }

    const isFormValid = () => {
        let flag = true;
        let nombreState = true;
        let telefonoState = true;
        let correoState = true;
        //Nombre
        if(validator.isEmpty(nombre)){
            flag = false;
            nombreState = false;
        }
        //Teléfono
        if(!validator.isMobilePhone(telefono, ['es-MX']) || telefono.length !== 10){
            flag = false;
            telefonoState = false;
        }
        //Correo
        if(!validator.isEmail(correo)){
            flag = false;
            correoState = false;
        }
        //Username
        if(!validator.isEmpty(username)){
            flag = false;
        }
        //Actualización de estado
        setFormValidation({
            ...formValidation,
            nombre: nombreState,
            telefono: telefonoState,
            correo: correoState
        });
        return flag;
    }

    const isFieldValid = (field = '') => {
        let nombreState = undefined;
        let telefonoState = undefined;
        let correoState = undefined;
        switch (field) {
            case 'nombre':
                if(!validator.isEmpty(nombre)){
                    nombreState = true;
                }
                break;
            case 'telefono':
                if(validator.isMobilePhone(telefono, ['es-MX']) && telefono.length === 10){
                    telefonoState = true;
                }else if(telefono !== ''){
                    telefonoState = false;
                }
                break;
            case 'correo':
                if(validator.isEmail(correo)){
                    correoState = true;
                }else if(correo !== ''){
                    correoState = false;
                }
                break;
        
            default:
                break;
        }
        //Actualización de estado
        setFormValidation({
            ...formValidation,
            nombre: nombreState,
            telefono: telefonoState,
            correo: correoState
        });
    }

    const clearForm = () => {
        setFormValidation({
            ...formValidation,
            nombre: null,
            telefono: null,
            correo: null,
            mensaje: null
        });
    }

    useEffect(() => {
        isFieldValid('nombre');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nombre])

    useEffect(() => {
        isFieldValid('telefono');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [telefono])

    useEffect(() => {
        isFieldValid('correo');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [correo])
    

    return (
        <div className='contacto-form-container' style={{backgroundColor: (theme === 'dark') ? '#1B1B1B' : 'transparent'}}>
            <div className='container'>
                <div className='contacto-form-header' style={{color: (theme !== 'dark') ? 'var(--gold-color)' : 'white'}}>Contáctenos</div>
                <div className='contacto-form-subheader' style={{color: (theme !== 'dark') ? 'black' : 'var(--white-nav-color)'}}>Si desea más información por favor póngase en contacto con nosotros y un asesor se comunicará con usted.</div>
                <form onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className='col-sm-6'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }${ (formValidation.nombre != null) ? ((formValidation.nombre) ? ' is-valid' : ' is-invalid') : '' }`}
                                    name="nombre"
                                    onChange={handleInputChange}
                                    placeholder="Nombre"
                                    type="text"
                                    value={nombre}
                                />
                                <div className="invalid-feedback">
                                    Nombre invalido
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-6'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }${ (formValidation.telefono != null) ? ((formValidation.telefono) ? ' is-valid' : ' is-invalid') : '' }`}
                                    name="telefono"
                                    onChange={handleInputChange}
                                    placeholder="Teléfono"
                                    type="text"
                                    value={telefono}
                                />
                                <div className="invalid-feedback">
                                    Teléfono invalido, considera marcación a 10 dígitos sin espacios ni guiones
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }${ (formValidation.correo != null) ? ((formValidation.correo) ? ' is-valid' : ' is-invalid') : '' }`}
                                    name="correo"
                                    onChange={handleInputChange}
                                    placeholder="Correo electrónico"
                                    type="text"
                                    value={correo}
                                />
                                <div className="invalid-feedback">
                                    Correo invalido
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='mb-4'>
                                <textarea
                                    name='mensaje'
                                    className={`form-control form-control-contacto ${ (theme !== 'dark') ? 'form-control-contacto-ligth' : 'form-control-contacto-dark' }`}
                                    onChange={handleInputChange}
                                    placeholder='Mensaje'
                                    rows={5}
                                    value={mensaje}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row d-none'>
                        <div className='col'>
                            <div className='mb-4'>
                                <input
                                    autoComplete="off"                                    
                                    name="username"
                                    onChange={handleInputChange}
                                    placeholder="Username"
                                    type="text"
                                    value={username}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <button className='btn btn-dark form-control-contacto-btn' type='submit'>Enviar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

ContactoForm.propTypes = {
    theme: PropTypes.string.isRequired,
}