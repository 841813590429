import { Outlet, NavLink, Link } from "react-router-dom";
import { ScrollRestoration } from "react-router-dom";

export default function Root() {
    return (
      <>
        <ScrollRestoration
          getKey={(location, matches) => {
            // default behavior
            return location.key;
          }}
        />
        {/* Navbar */}
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark">
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="https://www.notaria47.org/wp-content/uploads/2021/03/logo-2.png" alt="" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse navbar-ul-container" id="navbarSupportedContent">
              <div>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/" end>Inicio</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/servicios">Servicios</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/fundamentos">Fundamentos</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/notario">Notario</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/equipo">Equipo</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-item nav-link" to="/contacto">Contacto</NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div className="main-container">
          <Outlet />
        </div>
        {/* Footer */}
        <footer>
          <div className="footer-top p-4">
            <div className="container">
              <div className="row">
                  <div className="d-sm-none d-lg-block col-md-3 centrar-vh">
                    <img className="footer-logo" src="https://www.notaria47.org/wp-content/uploads/2021/03/logo-2.png" alt="Notaria 47" />
                  </div>
                  <div className="col-sm-4 col-lg-3">
                    <div className="footer-header">Notaria 47</div>
                    <div className="footer-content">La Notaria 47 de Aguascalientes es hoy una de las más destacadas en nuestro Estado. Durante los últimos años nos hemos inmerso en un ambicioso proceso de mejora y modernización, cuyos resultados hoy se ven reflejados en la satisfacción de nuestros clientes.</div>
                  </div>
                  <div className="col-sm-4 col-lg-3">
                    <div className="footer-header">Contáctanos</div>
                    <div className="footer-content">Monte Everest No. 100 esquina Sierra Fría Fracc. Bosques del Prado Norte Aguascalientes, Ags.</div>
                    <div className="footer-content p-1">contacto@notaria47.org</div>
                    <div className="footer-content p-1">(449) 996 58 02 al 04</div>
                    <div className="footer-content p-1">(449) 996 19 74</div>
                  </div>
                  <div className="col-sm-4 col-lg-3">
                    <div className="footer-header">Navegación</div>
                    <ul className="footer-ul">
                      <li><Link to="/servicios">Servicios</Link></li>
                      <li><Link to="/fundamentos">Fundamentos</Link></li>
                      <li><Link to="/notario">Notario</Link></li>
                      <li><Link to="/equipo">Equipo</Link></li>
                      <li><Link to="/contacto">Contacto</Link></li>
                      <li><Link to="/aviso-privacidad">Aviso de privacidad</Link></li>
                    </ul>
                  </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="footer-bottom text-center p-3">© Notaria 47 Pública | Derechos Reservados</div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* Footer */}
      </>
    );
  }