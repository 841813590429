import { types } from "../types/types";

const initialState = {
    servicio: 0
};

export const detalleServicioReducer=(state = initialState, action)=>{
    switch (action.type){
        case types.detalleServicioSetServicio:
            return{
                ...state,
                servicio: action.payload,
            }
        
        default:
            return state;
            
    }

}